.App {
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
}

h1{
  font-family: 'Sansita', sans-serif;
  text-transform: uppercase;
}
body{
  width:100%;
  font-size:50px;
  
}

.bg-color{
  background-color: #000;
}

a{
  text-decoration:none;
  display: inline-block;
}

.container{
  max-width: 940px;
  margin-left:auto;
  margin-right:auto;
}

.intro{
  background-color: #9b6a6c;
  color: #ffffff;
  padding: 70px 0;

}

.project-title{
  font-family: 'Sansita', sans-serif;
  color: #9b6a6c;
  font-weight:500;
  font-size:50px;
}


.cta-wrapper{
  display:flex;
  justify-content: space-between;

}

/* BUTTON STYLING */

.pill-button{
  font-family: "Sansita";
  text-decoration: none;
  font-weight:bold;
  text-transform: uppercase;
  font-size:25px;
  color: #000;
  border-radius: 99999px;
  background-color: #e2b4bd;
  border: 2px solid #000;
  padding: 1em 2em 1em 2em;
  box-shadow: 0px 12px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 12px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 12px 0px rgba(0,0,0,0.75);
  -webkit-transition: all 0.25s ease-in-out 0s;
  -moz-transition:all 0.25s ease-in-out 0s;
  transition: all 0.25s ease-in-out 0s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

}

.pill-button:hover{
  box-shadow: 0px -1px 0px  rgba(162, 67, 67, 0.75);
  transform: translateY(12px);
  border: 2px solid #000;
  background: hsl(348, 44%, 80%);
  color: #000;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: all 0.25s ease-in-out 0s;
  cursor: pointer;
}

/*  END BUTTON STYLING */


.wavy-underline{
  text-decoration: underline;
  text-decoration-style: wavy;
  text-underline-offset: 24px;
  line-height:9rem;
}


/* marquee styling  */
.marquee {
  animation-delay: 2s;
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 200px;
  overflow-x: hidden;

}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 15s linear infinite;
}

@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}



.tech-stack-container {
  border: 2px solid black;
  font-size:25px;
  margin: 0 auto;
}

.content{
  font-weight:bold;
}

footer{
  margin:50px;
}

.section-title{
  font-family: 'Sansita', sans-serif;
  font-weight:600;
  font-size:65px;

  letter-spacing: 3px;
  text-transform: uppercase;
}

.good-space{
  border:5px solid rgb(239, 228, 213);
  margin-bottom: 80px;
}

.plantify{
  border:5px solid rgb(239, 228, 213);
}

.project-info{
  font-size:18px;
}

.project-item{
  padding: 20px 80px;
}

.btn-container{
  display:flex;

}

.text-sm{
  font-size:18px;
}

.icon{
  margin: 0 20px;
  color: black;

}


.video-btn{
  display:none;
}
.icon:hover{
  color:#e2b4bd;
}

.desktop-only{
  margin-top:50px;
}

.cta-wrapper-mobile{
  display:none;
}

@media screen and (max-width:768px) {

  body{
    font-size:18px;
    line-height:1.8rem;
  }

  .project-container{
    text-align:left;
  }
  .section-title{
    font-size:20px;

  }
  
  .project-title{
    font-size:24px;
    font-weight:500;
  }
  .desktop-only{
    display:none;
  }

  .text-center{
    text-align: center;
  }

  .subtext{
    font-size:14px;
  }
  .container{
    max-width:365px;
    padding:20px;
    margin:auto;
  }
  .intro{
    font-size:18px;
  }  
  .marquee{
    font-size:18px;
    height:100px;
  }

  .cta-wrapper-mobile{
    display:block;
  }

  .pill-button{
    font-size:13px;
    margin:10px 0;
    box-shadow: unset;
  }


  .video-btn{
    display:block;
  }

  .project-item{
    padding:25px 14px;
  }

  .pill-button:hover{
    transform: none;
  }

  .wavy-underline{
    line-height:5rem;
  }


  footer{
    flex-direction: column;
  }

  .logo{
    width:150px; 
  }
}